import React from "react";

import * as Styles from "./Introduction.styles";

import ContactButton from "../../general/ContactButton/ContactButton";

export default () => (
  <Styles.Container>
    <Styles.Columns>
      <Styles.TextColumn>
        <Styles.H1>DJ för bröllop i Stockholm</Styles.H1>
        <p>
          Vårt mål är att ge dig den musik som passar dig och dina gäster bäst.
          Att få gäster, unga som gamla, att dansa är något vi brinner för. Vi
          gör ditt bröllop personligt genom att tillsammans med dig skräddarsy
          din kväll. Med hjälp av vår långa erfarenhet av vad som håller ett
          dansgolv vid liv skapar vi en verkligt minnesvärd natt!
        </p>
      </Styles.TextColumn>
    </Styles.Columns>
    <ContactButton />
  </Styles.Container>
);
