import styled from "styled-components";
import { Color } from "../../../styles";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 64px 0 136px 0;
  background-color: ${Color.BACKGROUND_DARK_PRIMARY};
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  margin-top: -48px;
  clip-path: polygon(0 0, 100% 24px, 100% 100%, 0 calc(100% - 24px));
  // color: ${Color.TEXT_BLACK_PRIMARY};

  @media only screen and (max-width: 960px) {
    width: auto;
    padding-bottom: 48px;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 912px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }
`;

export const TextColumn = styled.div`
  flex: 1 1 460px;
  margin-right: 60px;

  @media only screen and (max-width: 960px) {
    margin: 12px 0;
    width: auto;
  }

  > .Quote {
    width: 400px;
    margin: 0 0 -64px 240px;

    @media only screen and (max-width: 960px) {
      margin: 24px 24px 0 24px;
      width: auto;
    }
  }
`;

export const H1 = styled.h1`
  width: max-content;
  font-size: 2rem;
  letterspacing: 0.3rem;
  font-weight: 600;
  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;

export const Step = styled.div`
  display: flex;
  // align-items: center;
  justify-content: space-between;
  width: 760px;
  margin: 48px auto -64px auto;

  @media only screen and (max-width: 960px) {
    justify-content: center;
    flex-wrap: wrap;
    width: auto;
    margin: 48px 24px;

    &.reverse {
      flex-wrap: wrap-reverse;
    }
  }

  > .gatsby-image-wrapper {
    flex: 0 0 150px;

    width: 150px;
    height: 150px;
    box-sizing: border-box;
    border: 6px solid rgba(255, 255, 255, 0.9);
    border-radius: 50%;

    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 960px) {
      flex: 0 0 120px;
      width: 120px;
      height: 120px;
      margin-bottom: 24px;
    }
  }
`;

export const StepContent = styled.div`
  flex: 0 0 570px;

  @media only screen and (max-width: 960px) {
    flex: 1 1 100%;
    > p {
      display: block;
      margin: 0 auto;
      max-width: 400px;
    }
  }
  > blockquote {
    width: max-content;
    display: block;
  }
`;

export const BookingLink = styled.span`
  color: ${Color.ORANGE_PRIMARY};

  &:hover {
    cursor: pointer;
  }
`;
