import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import * as Styles from "./UserStories.styles";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        person1: file(relativePath: { eq: "privatfest/person1.jpg" }) {
          childImageSharp {
            fixed(width: 80, height: 80, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        person2: file(relativePath: { eq: "privatfest/person2.jpg" }) {
          childImageSharp {
            fixed(width: 80, height: 80, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Styles.Container>
        <Styles.Columns>
          <Styles.TextColumn>
            <Img
              fixed={data.person1.childImageSharp.fixed}
              style={{ display: "block" }}
              alt="DJ rekommendation bröllop"
              title="DJ rekommendation bröllop"
            />
            <p>
              Ni var grymma! Man fick snabb respons och ni hittade alltid nya
              lösningar till de problem som uppstod. Utöver superbra bemötande
              är ni grymma på att hålla igång dansgolvet natten lång.
            </p>
            <Styles.Name>Miranda</Styles.Name>
          </Styles.TextColumn>
          <Styles.TextColumn>
            <Img
              fixed={data.person2.childImageSharp.fixed}
              style={{ display: "block" }}
              alt="DJ rekommendation bröllop"
              title="DJ rekommendation bröllop"
            />
            <p>
              Ni var jätteduktiga och professionella. Ni förstod vad vi behövde
              inför vår fest och anpassade musiken till hur stämningen kändes i
              stunden. Jag hörde kollegor bara nämna bra saker om DJ:n. Utöver
              det hjälpte ni till med val av ljus och få till rätt stämning som
              vi sökte efter! Rekommenderar med glädje Platoon DJs och kommer
              definitivt väljer er igen!
            </p>
            <Styles.Name>Hagob</Styles.Name>
          </Styles.TextColumn>
        </Styles.Columns>
      </Styles.Container>
    )}
  />
);
