import React from "react";

import Header from "../components/general/Header/Header";
import Footer from "../components/general/Footer/Footer";
import Contact from "../components/general/Contact/Contact";

import video from "../assets/videos/bannerVideoCompressed3.mp4";

import Banner from "../components/general/Banner/Banner";
import Introduction from "../components/brollop/Introduction/Introduction";
import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";
import MusicSelection from "../components/general/MusicSelection/MusicSelection";
import Rundown from "../components/brollop/Rundown/Rundown";
import UserStories from "../components/brollop/UserStories/UserStories";

export default () => (
  <div>
    <CustomHelmet
      title="DJ för bröllop i Stockholm - Platoon DJs"
      description="Hyra DJ för bröllop i Stockholm"
      keywords="dj, bröllop, stockholm, hyra"
      canonical="/brollop/"
    />
    <Header />
    <Banner
      videoUrl={video}
      tagline="En skräddarsydd lösning för er speciella dag"
    />
    <Introduction />
    <MusicSelection>
      <p>
        Från Abba till Avicii, oavsett preferens, har vi garanterat en DJ som
        kan leverera en oförglömlig kväll för dig och dina gäster.{" "}
      </p>
    </MusicSelection>
    <Rundown />
    <UserStories />
    <Contact />
    <Footer />
  </div>
);
