import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { scroller } from "react-scroll";

import Img from "gatsby-image";

import * as Styles from "./Rundown.styles";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        consultation: file(relativePath: { eq: "brollop/consultation.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 150
              maxHeight: 150
              quality: 90
              srcSetBreakpoints: [120, 150]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        setup: file(relativePath: { eq: "brollop/setup.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 150
              maxHeight: 150
              quality: 90
              srcSetBreakpoints: [120, 150]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        weddingParty: file(relativePath: { eq: "brollop/weddingParty.jpg" }) {
          childImageSharp {
            fluid(
              maxWidth: 150
              maxHeight: 150
              quality: 90
              srcSetBreakpoints: [120, 150]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Styles.Container>
        <Styles.Columns>
          <Styles.TextColumn>
            <Styles.H1>Att jobba med oss är smidigt</Styles.H1>
            <p>
              Enligt oss ska du som kund inte behöva oroa dig för detaljer kring
              logistik. Från bokning till den sista låten, är vi där och jobbar
              smidigt i bakgrunden, så att du kan fokusera på att njuta av din
              fest.
            </p>
            <Styles.Step style={{ alignItems: "center" }}>
              <Img
                fluid={data.consultation.childImageSharp.fluid}
                alt="Boka DJ konsultation"
                title="Boka DJ konsultation"
              />
              <Styles.StepContent>
                <p>
                  Vi återkopplar snarast (oftast inom 24 timmar) från det att ni
                  skickat in en
                  <Styles.BookingLink
                    onClick={() => {
                      scroller.scrollTo("contact", {
                        duration: 500,
                        smooth: "easeInOutQuint"
                      });
                      setTimeout(() => {
                        document.getElementById("contactNameInput").focus();
                      }, 500);
                    }}
                  >
                    {" "}
                    intresseanmälan
                  </Styles.BookingLink>{" "}
                  till oss och fortsätter därifrån på era villkor.
                </p>
              </Styles.StepContent>
            </Styles.Step>
            {/*<Quote textAlign="left" name="Kim &amp; Petter">
              Very well cykelställ
                  </Quote>*/}
            <Styles.Step className="reverse" style={{ alignItems: "center" }}>
              <Styles.StepContent>
                <p>
                  Tillsammans med ansvariga för lokalen synkar vi detaljer och
                  logistik, så att ni kan fokusera på annat. Vi sköter all
                  uppriggning av högtalare, mixerbord etc.
                </p>
              </Styles.StepContent>
              <Img
                fluid={data.setup.childImageSharp.fluid}
                alt="Boka DJ Ljud Ljus Bröllop"
                title="Boka DJ Ljud Ljus Bröllop"
              />
            </Styles.Step>
            {/*<Quote textAlign="left" name="Kim &amp; Petter">
              Very well cykelställ
                  </Quote>*/}
            <Styles.Step style={{ alignItems: "center" }}>
              <Img
                fluid={data.weddingParty.childImageSharp.fluid}
                alt="Boka DJ Bröllop"
                title="Boka DJ Bröllop"
              />
              <Styles.StepContent>
                <p>
                  Nu är det bara att koppla av, låta oss göra det vi gör bäst
                  och njuta av en helt oförglömlig fest. Vi garanterar er en
                  riktigt härlig fest som definitivt fulländar er speciella dag.
                </p>
              </Styles.StepContent>
            </Styles.Step>
          </Styles.TextColumn>
        </Styles.Columns>
      </Styles.Container>
    )}
  />
);
